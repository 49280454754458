import { lereroFetch } from '../utils/fetch';

export const getBundleDetails = async (bundleId) => {
  const { data } = await lereroFetch('GET', `/bundles/${bundleId}`);

  return data;
};

export const getBundleRecommendation = async ({
  page = 1,
  sort = 'newest',
  limit = 5,
  price = '',
  language = '',
  type = '',
  readingTime = '',
  provider = '',
  notIn = '',
  random = false,
}) => {
  const notInParams = notIn !== '' ? `&notIn=${notIn}` : '';
  const randomParams = random ? `&random=yes` : '';

  const { data } = await lereroFetch(
    'GET',
    `/bundles?page=${page}&limit=${limit}${price}${type}${readingTime}${language}${
      provider === null ? '' : provider
    }&sort=${sort}${notInParams}${randomParams}`
  );

  return data.data;
};
