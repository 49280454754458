const useLandingCardItemData = ( { item, itemType } ) => {

  const getSlug = () => {
    switch ( itemType ) {
      case 'products':
      case 'bundles':
        return 'slug';
      default:
        return 'courseSlug';
    }
  };

  const getUserData = () => {
    let index;
    let companyLogoIndex = 'companyLogo';

    switch ( itemType ) {
      case 'products':
      case 'bundles':
        index = 'provider';
        break;
      default:
        index = 'courseOrganizer';
        break;
    }

    if ( itemType === 'bundles' ) {
      companyLogoIndex = 'path';
    }

    return {
      picture: item[ index ]?.logo,
      courseOrganizer: item[ index ]?.code,
      name: item[ index ]?.name,
      companyLogo: item[ index ]?.[ companyLogoIndex ] ? item[ index ][ companyLogoIndex ] : '/landing/card/picture.svg',
    };
  };

  return {
    title: item.name,
    image: item.picture || '/ahihi.png',
    price: item.price,
    discount: item.discount,
    slug: item[ getSlug() ],
    category: item.category,
    user: getUserData(),
  };
};


export default useLandingCardItemData;
