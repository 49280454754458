import { lereroFetch as fetch } from 'utils/fetch';

const fetchProductDetail = async (url) => {
  const response = await fetch('get', '/products/' + url);
  return response;
};

export const getProductDetail = async (courseName) => {
  try {
    const data = await fetchProductDetail(courseName);

    if (data.success) {
      return data.data;
    }
  } catch (error) {}
  return null;
};

export const getProductList = async ({
  page = 1,
  sort = 'newest',
  limit = 5,
  price = '',
  language = '',
  type = '',
  readingTime = '',
  provider = '',
  notIn = '',
  random = false,
}) => {
  try {
    const notInParams = notIn !== '' ? `&notIn=${notIn}` : '';
    const randomParams = random ? `&random=yes` : '';

    const response = await fetchProductDetail(
      `?page=${page}&limit=${limit}${price}${type}${readingTime}${language}${
        provider === null ? '' : provider
      }&sort=${sort}${notInParams}${randomParams}`
    );

    if (response.success) {
      return response.data?.data;
    }
  } catch (error) {}
  return null;
};
