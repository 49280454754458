/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useState, useEffect, useMemo } from 'react';
import Router from 'next/router';

// Component
import ModalCheckout from 'components/CourseDescription/ModalCheckout';
import PriceContainer from 'components/PriceContainer';

//Services
import { getCourseDetail } from 'services/course-description';
import { useImageHandler } from 'utils/custom_hooks';
import { cancelEventBubble } from 'utils/cancel_event_bubble';
import { getProductDetail } from 'services/products';
import { synchronizedSingleCartData } from 'utils/manipulate_array';
import { generateCoverImageUrl } from 'utils/generate_cover_image_url';
import useLandingCardItemData from '../../hooks/useLandingCardItemData';
import { getBundleDetails } from '../../services/bundles';

import { truncateText } from 'utils/helper';

export default function Card(props) {
  const { isLoading, setIsLoading, isProduct, item, itemType, activeTabItemName, language } = props;

  const [cart, setCart] = useState([]);
  const [openModalCheckout, setOpenModalCheckout] = useState(false);
  const { title, image, price, discount, slug, category, user } = useLandingCardItemData({ item, itemType });
  const memoizedOptionList = useMemo(() => [user.picture, user.companyLogo], [user.picture, user.companyLogo]);
  const { currentImage, handleImageToDefault, handleIsImageAvailable } = useImageHandler({
    optionList: memoizedOptionList,
    data: user,
  });

  const addToCart = async () => {
    setIsLoading(true);
    let data;
    switch ( itemType ) {
      case 'products':
        data = await getProductDetail( slug );
        break;
      case 'bundles':
        data = await getBundleDetails( slug );
        break;
      default:
        data = await getCourseDetail( slug );
        break;
    }

    if (!data) {
      return;
    }

    const newCart = synchronizedSingleCartData({ item: data, isProduct: isProduct });

    // data.name = data.courseTitle;
    let list_cart = [...cart];
    let new_data = [];
    let openModal = false;

    if (list_cart.filter((cart) => cart.itemId == newCart.itemId).length !== 0) {
      new_data = list_cart.filter((cart) => cart.itemId !== newCart.itemId);
    } else {
      list_cart.forEach((list) => {
        new_data.push(list);
      });
      new_data.push(newCart);
      openModal = true;
    }

    const synchronizedCartData = new_data.map((item) => {
      return synchronizedSingleCartData({ item: item, isProduct: item.isProduct || false });
    });

    setCart(synchronizedCartData);

    localStorage.setItem('cart', JSON.stringify(synchronizedCartData));
    localStorage.setItem('slug', slug);

    if (openModal) {
      setOpenModalCheckout(true);
    } else {
      Router.reload(window.location.href);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const currentCart = localStorage.getItem('cart');

    if (currentCart) {
      const newCart = JSON.parse(currentCart).map((cartItem) => {
        return synchronizedSingleCartData({ item: cartItem, isProduct: cartItem.isProduct || false });
      });

      setCart(newCart);
    }
  }, []);

  return (
    <div className="landing-card-wrapper" onClick={() => Router.push(`/${activeTabItemName}/${slug}`)}>
      <div className="landing-card-content-container">
        <div className="landing-card-left-container">
          <div className="landing-card-image-container">
            <img className="landing-card-image" src={generateCoverImageUrl(image)} alt={title} />
          </div>
        </div>
        <div className="landing-card-category-text">{category}</div>
        <div className="landing-card-right-container">
          <div>
            <div dir={language === 'Arabic' ? 'rtl' : 'ltr'} className="landing-card-title" style={{textWrap: "break-word"}}>
              {truncateText(title, 45, true)}
            </div>
            <div className="landing-card-user-picture-container">
              {!handleIsImageAvailable(user.picture) ? (
                <>
                  <div className="user-picture-container company-logo">
                    <img
                      className="landing-card-user-picture"
                      src={currentImage}
                      onError={handleImageToDefault}
                      alt={user.name}
                    />
                  </div>
                  <p className="landing-card-user-name name-in-logo">{user.name}</p>
                </>
              ) : (
                <div className="user-picture-container course-logo">
                  <img
                    className="landing-card-user-picture"
                    src={currentImage}
                    onError={handleImageToDefault}
                    alt={user.name}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <p className="landing-card-user-name mt-4">By: {user.name}</p>
            <PriceContainer
              className="landing-card-price"
              variableDiscount={[discount]}
              variablePrice={[price]}
              isMedium
              courseOrganizer={user.courseOrganizer}
            />
          </div>
        </div>
      </div>
      <div className="landing-card__information">
        <button className="show landing-card-button">More Information</button>
        <div className="hidden d-flex gap-4">
          <button onClick={() => Router.push(`/${activeTabItemName}/${slug}`)} className="landing-card-button active">
            More Information
          </button>
          <img
            role="button"
            style={{
              height: '5rem',
              width: '5rem',
              border: '1px solid #066bfb',
              borderRadius: '.4rem',
            }}
            src="/landing/card/cart.gif"
            onClick={(e) => {
              cancelEventBubble(e);
              addToCart();
            }}
          />
        </div>
      </div>

      {openModalCheckout && (
        <ModalCheckout
          setOpen={setOpenModalCheckout}
          open={openModalCheckout}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          language={language}
        />
      )}
    </div>
  );
}
