import { lereroFetch as fetch } from 'utils/fetch';

const fetchCourseDetail = async (url) => {
  const response = await fetch('get', '/courses/' + url);
  return response;
};

export const getCourseDetail = async (courseName) => {
  try {
    const data = await fetchCourseDetail(courseName);

    if (data.success) {
      return data.data[0];
    }
  } catch (error) {}
  return null;
};

export const getCourseTestimonial = async (courseId) => {
  try {
    const data = await fetchCourseDetail(courseId + '/course-testimonials');
    if (data.success) {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getCourseFaq = async (courseId) => {
  try {
    const data = await fetchCourseDetail(courseId + '/faqs');
    if (data.success) {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getCourseRecommendation = async (courseId) => {
  try {
    const data = await fetchCourseDetail(courseId + '/recommendations');
    if (data.success) {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};
